import { useMutation, useQuery } from '@apollo/client';
import {
  FormHelperText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@material-ui/core';
import { Favorite as FavoriteIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlert } from 'react-alert';
import * as yup from 'yup';
import { HeartTeam } from '../../../interfaces/HeartTeam';
import { Spectator } from '../../../interfaces/Spectator';
import { useSpectator } from '../Context';
import changeUserTeamMutation from '../gql/changeUserTeamMutation';
import listTeamsQuery from '../gql/listTeamsQuery';

interface FavoriteTeamProps {
  isDeleted: boolean;
}

const FavoriteTeam = ({ isDeleted }: FavoriteTeamProps) => {
  const alert = useAlert();
  const [submitLoading, setSubmitLoading] = useState(false);
  const { selectedSpectator, setSelectedSpectator } = useSpectator();
  const { loading, data } = useQuery<{ teams: { items: HeartTeam[] } }>(
    listTeamsQuery,
  );
  const [changeUserTeam] = useMutation(changeUserTeamMutation);
  const formik = useFormik<{ favoriteTeamId?: HeartTeam['id'] }>({
    enableReinitialize: true,
    initialValues: { favoriteTeamId: selectedSpectator?.favoriteTeam?.id },
    validationSchema: yup.object().shape({
      favoriteTeamId: yup.string().required('Campo obrigatório'),
    }),
    onSubmit: async (values) => {
      try {
        setSubmitLoading(true);
        const variables = {
          input: {
            userId: selectedSpectator?.id,
            teamId: values.favoriteTeamId,
          },
        };
        await changeUserTeam({ variables });
        const favoriteTeam = data!.teams.items.find(
          (team) => team.id === values.favoriteTeamId,
        );
        const newSelectedSpectator = { ...selectedSpectator, favoriteTeam };
        setSelectedSpectator(newSelectedSpectator as Spectator);
        alert.success('Resposta atualizada com sucesso!');
      } catch {
        alert.error('Erro ao atualizar resposta');
      } finally {
        setSubmitLoading(false);
      }
    },
  });
  if (loading) {
    return <div>Loading</div>;
  }
  if (!selectedSpectator) return null;
  return (
    <Stack direction="column" spacing={4}>
      <Stack alignItems="center" direction="row">
        <FavoriteIcon
          sx={{ color: 'secondary.dark', height: 16, mr: 4, width: 16 }}
        />
        <Typography sx={{ color: 'grey.200', fontSize: 14, fontWeight: 600 }}>
          Redefinir time do coração.
        </Typography>
      </Stack>
      <Typography sx={{ color: 'grey.200', fontSize: 14 }}>
        Ao alterar o time do coração, o espectador deixará de receber
        notificações relacionadas ao time anterior. Certifique-se que é
        realmente isso que você deseja fazer.
      </Typography>
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Time do coração atual
      </Typography>
      <TextField
        placeholder={selectedSpectator?.favoriteTeam?.name}
        fullWidth
        InputProps={{ readOnly: true }}
        sx={{ width: 229 }}
      />
      <Typography sx={{ color: 'grey.300', fontSize: 14 }}>
        Novo time
      </Typography>
      <Select
        name="favoriteTeamId"
        displayEmpty
        disabled={isDeleted}
        error={!!formik.errors.favoriteTeamId && formik.touched.favoriteTeamId}
        value={formik.values.favoriteTeamId}
        sx={{ width: 229 }}
        onChange={formik.handleChange}
      >
        <MenuItem value="">Selecione uma resposta:</MenuItem>
        {data!.teams.items.map((team) => (
          <MenuItem key={team.id} value={team.id}>
            {team.name}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText error>
        {!!formik.errors.favoriteTeamId && formik.errors.favoriteTeamId}
      </FormHelperText>
      <LoadingButton
        loading={submitLoading}
        disabled={isDeleted}
        sx={{ alignSelf: 'flex-start' }}
        onClick={() => formik.handleSubmit()}
      >
        Redefinir time do coração
      </LoadingButton>
    </Stack>
  );
};

export default FavoriteTeam;
