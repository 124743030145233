import {
  Avatar,
  Box,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { SxProps, Theme } from '@material-ui/system';
import { useSpectator } from '../Context';
import { getChipColor, StatusColor } from '../utils/getChipColor';
import { formatCPF } from '../utils/string';

interface SpectatorListRowProps {
  data: any;
  isLastItem: boolean;
  sx?: SxProps<Theme>;
}

const SpectatorListRow = ({ sx, data, isLastItem }: SpectatorListRowProps) => {
  const { handleOpenMenu } = useSpectator();
  const chipStyle = getChipColor(data.status as StatusColor);
  return (
      <Grid
        container
        sx={{
          ...sx,
          borderColor: 'grey.500',
          borderStyle: 'solid',
          borderWidth: 0,
          borderBottomWidth: !isLastItem ? 1 : 0,
          pb: !isLastItem ? 4 : 0,
          height: '100%',
        }}
      >
        <Grid item xs={4}>
          <Stack direction="row">
            <Avatar
              alt="avatar"
              src={data.user?.avatar}
              sx={{ width: 42, height: 42 }}
            />
            <Stack direction="column" sx={{ marginLeft: 4 }}>
              <Stack alignItems="center" direction="row" sx={{ mb: 1 }}>
                <Typography
                  sx={{
                    color: 'grey.100',
                    maxWidth: 180,
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data.user?.name}
                </Typography>
                {!!data.user?.deletedAt && (
                  <Chip
                    label="Excluído"
                    variant="outlined"
                    size="small"
                    sx={{
                      alignSelf: 'baseline',
                      backgroundColor: 'grey.500',
                      color: 'grey.200',
                      borderRadius: '4px',
                      border: `1px solid`,
                      borderColor: 'grey.200',
                      ml: 2,
                    }}
                  />
                )}
              </Stack>
              <Typography sx={{ color: 'grey.400', fontSize: 12 }}>
                {data.user?.email}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction="column"
            sx={{ justifyContent: 'center' }}
            spacing={1}
          >
            <Typography sx={{ color: 'grey.100' }}>
              {data.user?.phone}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={2}>
          <Stack
            direction="column"
            sx={{ justifyContent: 'center' }}
            spacing={1}
          >
            <Typography sx={{ color: 'grey.100' }}>
              {formatCPF(data.user?.cpf)}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={1}>
          <Stack
            direction="column"
            sx={{ justifyContent: 'center' }}
            spacing={1}
          >
            <Chip
              label={chipStyle.label}
              variant="outlined"
              size="small"
              sx={{
                alignSelf: 'flex-start',
                backgroundColor: chipStyle.bgColor,
                color: 'white',
                borderRadius: '4px',
                border: `1px solid ${chipStyle.border}`,
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs>
          <Box
            bgcolor="grey.500"
            sx={{
              display: 'flex',
              marginLeft: 'auto',
              width: 48,
              height: 40,
              p: 1,
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
            }}
          >
            <IconButton onClick={(e) => handleOpenMenu(e, data.user)}>
              <MoreVert sx={{ color: 'grey.300' }} />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
  );
};

export default SpectatorListRow;
