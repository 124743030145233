import { useMutation, useQuery } from '@apollo/client';
import { OutlinedInput, Stack, Typography } from '@material-ui/core';
import { Lock as LockIcon } from '@material-ui/icons';
import { LoadingButton } from '@material-ui/lab';
import { useContext, useState } from 'react';
import { useAlert } from 'react-alert';
import { Context, useSpectator } from '../Context';
import cancelUserAccountMutation from '../gql/cancelAccountMutation';
import userQuery from '../gql/userQuery';

interface DeactivateAccountProps {
  isDeleted: boolean;
}

const DeactivateAccount = ({ isDeleted }: DeactivateAccountProps) => {
  const alert = useAlert();
  const [disableReason, setDisableReason] = useState('');
  const [isCanceling, setIsCanceling] = useState(false);
  const { listSpectators, setData } = useContext(Context);
  const { selectedSpectator } = useSpectator();
  const [cancelAccount] = useMutation(cancelUserAccountMutation);
  const { loading, data } = useQuery(userQuery, {
    variables: { id: selectedSpectator!.id },
  });
  if (!selectedSpectator) return null;
  if (loading) return <div>Carregando...</div>;
  const cancelUserAccount = async () => {
    try {
      setIsCanceling(true);
      await cancelAccount({
        variables: { input: { userId: selectedSpectator!.id } },
      });
      const haveAMonthlyPlan = data.user.userPlans.some(
        (userPlan: any) => userPlan.plan.type === 'monthly',
      );
      const newListSpectators = listSpectators.map((listSpectator) => {
        const isCanceledUser = listSpectator.user.id === selectedSpectator.id;
        if (isCanceledUser) {
          return {
            ...listSpectator,
            status: haveAMonthlyPlan ? 'pre-cancelled' : 'cancelled',
          };
        }
        return listSpectator;
      });
      setData({ items: newListSpectators });
    } catch {
      alert.error('Ocorreu um erro ao cancelar a conta');
    } finally {
      setIsCanceling(false);
    }
  };
  return (
    <Stack direction="column" sx={{ pb: 6 }}>
      <Stack direction="row" sx={{ alignItems: 'center' }}>
        <LockIcon
          sx={{
            color: 'secondary.dark',
            mr: 4,
            width: 16,
            height: 16,
          }}
        />
        <Typography color="grey.200" sx={{ fontSize: 14, fontWeight: 600 }}>
          Desativar conta do espectador
        </Typography>
      </Stack>
      <Typography color="grey.200" sx={{ fontSize: 14, mt: 4 }}>
        Ao desativar um espectador, você irá retirar o seu acesso à plataforma.
        Se certifique de isso é realmente o que você deseja fazer.
      </Typography>
      <Typography color="grey.300" sx={{ fontSize: 14, mt: 6 }}>
        Justifique a desativação da conta{' '}
        <span style={{ color: '#FF545E' }}>*</span>
      </Typography>
      <OutlinedInput
        name="description"
        value={disableReason}
        disabled={isDeleted}
        placeholder="Explique os motivos pelos quais você decidiu desativar o espectador"
        multiline
        rows={4}
        sx={{
          color: 'grey.300',
          height: 100,
          mt: 1,
          px: 3,
          py: 2,
          '.MuiInputBase-inputMultiline': { fontSize: 12 },
        }}
        onChange={(e) => setDisableReason(e.target.value)}
      />
      <LoadingButton
        disabled={isDeleted}
        sx={{ fontSize: 14, mt: 4, alignSelf: 'flex-start' }}
        loading={isCanceling}
        onClick={cancelUserAccount}
      >
        Desativar conta do espectador
      </LoadingButton>
    </Stack>
  );
};

export default DeactivateAccount;
